import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import TeamCard from "../components/team-card"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function JuniorSection() {
  return ( 
    <Layout>

      <section className="container">

      <h1>Junior (Mixed) Teams</h1>
    
        <p>Below is a list of our current boys and mixed teams. If your child is interested in joining please email the team contact or use our <Link to="/contact">contact</Link> form.</p>
        <p>All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.</p>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

          <div className="col mt-4">
            <TeamCard teamName="Under 8s" image="/team-placeholder.jpg" age="School Year 3" ageInfo="DoB 01/09/2016 - 31/08/2017" league="n/a" contact="Paul Hallett" contactEmail="mailto:ph@yet-ai.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 9s - Blues Dual" image="/team-placeholder.jpg" age="School Year 4" ageInfo="DoB 01/09/2015 - 31/08/2016" league="n/a" contact="Steve Baker" contactEmail="mailto:stevenjgbaker@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 10s - Blues Dual" image="/team-placeholder.jpg" age="School Year 5" ageInfo="DoB 01/09/2014 - 31/08/2015" league="Garforth Junior League" contact="Pete Walsh" contactEmail="mailto:leedscityjnrs@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 10s - Colts" image="/team-placeholder.jpg" age="School Year 5" ageInfo="DoB 01/09/2014 - 31/08/2015" league="CravenA & WJFL" contact="Ian Cuthbert" contactEmail="mailto:ian.cuthbert@sky.com" />
          </div>

        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

          <div className="col mt-4">
            <TeamCard teamName="Under 11s - Blacks" image="/team-placeholder.jpg" age="School Year 6" ageInfo="DoB 01/09/2013 - 31/08/2014" league="Garforth Junior League" contact="David Jennings" contactEmail="mailto:davidjennings69@hotmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 11s - Blues" image="/team-placeholder.jpg" age="School Year 6" ageInfo="DoB 01/09/2013 - 31/08/2014" league="Garforth Junior League" contact="Steve Clayton" contactEmail="mailto:steveclayton00@hotmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 12s - Blues" image="/team-placeholder.jpg" age="School Year 7" ageInfo="DoB 01/09/2012 - 31/08/2013" league="Garforth Junior League" contact="Jonny White" contactEmail="mailto:Jonathanwhitephotography@hotmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 12s - Juniors" image="/team-placeholder.jpg" age="School Year 7" ageInfo="DoB 01/09/2012 - 31/08/2013" league="Garforth Junior League" contact="Gareth Poole" contactEmail="mailto:g_man_81@live.co.uk" />
          </div>

        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

          <div className="col mt-4">
            <TeamCard teamName="Under 12s - Colts" image="/team-placeholder.jpg" age="School Year 7" ageInfo="DoB 01/09/2012 - 31/08/2013" league="Garforth Junior League" contact="Chris Miall" contactEmail="mailto:miall350@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 13s - Blues" image="/boys-u12-blues.jpg" age="School Year 8" ageInfo="DoB 01/09/2011 - 31/08/2012" league="Garforth Junior League" contact="Sini Savic" contactEmail="mailto:sinisa_savic@hotmail.co.uk" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 13s - Colts" image="/boys-u12-colts.jpg" age="School Year 8" ageInfo="DoB 01/09/2011 - 31/08/2012" league="Garforth Junior League" contact="Ade Whitehouse" contactEmail="mailto:a.whitehouse@leeds.ac.uk" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 14s" image="/boys-u13.jpg" age="School Year 9" ageInfo="DoB 01/09/2010 - 31/08/2011" league="Garforth Junior League" contact="Duncan Colman" contactEmail="mailto:duncan@spikedigitalmarketing.co.uk" />
          </div>

        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

          <div className="col mt-4">
            <TeamCard teamName="Under 15s" image="/boys-u14.jpg" age="School Year 10" ageInfo="DoB 01/09/2009 - 31/08/2010" league="Garforth Junior League" contact="Phil Shaw" contactEmail="mailto:philjshaw@msn.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 16s GJFL" image="/boys-u15-juniors.jpg" age="School Year 11" ageInfo="DoB 01/09/2008 - 31/08/2009" league="Garforth Junior League" contact="Mike Inman" contactEmail="mailto:leedscityjnrs@gmail.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 16s HDJFL" image="/boys-u15-harrogate.jpg" age="School Year 11" ageInfo="DoB 01/09/2008 - 31/08/2009" league="Harrogate District League" contact="Rob Campey" contactEmail="mailto:Rob.campey@ntlworld.com" />
          </div>

          <div className="col mt-4">
            <TeamCard teamName="Under 17s - Colts"  image="/team-placeholder.jpg" age="School Year 12" ageInfo="DoB 01/09/2007 - 31/08/2008" league="Garforth Junior League" contact="James Cassidy" contactEmail="mailto:pamelajarvis77@icloud.com" />
          </div>

        </div>

      </section>

    </Layout>
  );
}

