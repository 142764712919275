import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function TeamCard(props) {

    return (
        <div className="card shadow-sm">
        <div className="card-header text-primary">{props.teamName}</div>
        <img src={props.image} />
        <div className="card-body">
          <dl className="row">
              <dt className="col-4">Age</dt>
              <dd className="col-8">{props.age}<span className="ms-2" title={props.ageInfo}><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon></span></dd>
              <dt className="col-4">League</dt>
              <dd className="col-8">{props.league}</dd>
              <dt className="col-4">Contact</dt>
              <dd className="col-8">{props.contact}<a className="ms-2" href={props.contactEmail}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a></dd>
          </dl>
        </div>
      </div>
    );
}
